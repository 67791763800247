import React from "react";
import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

import constants from "../../../../constants";

const ResponseModal = ({ createStatus, resetForm, message, responseData }) => {
  const success = createStatus === constants.LOADING_SUCCESS;
  const failure = createStatus === constants.LOADING_FAIL;
  const visible = success || failure;
  const header = success ? "Success" : "Failure";
  const handleDismiss = () =>
    success ? resetForm(true) : failure ? resetForm(false) : null;

  const SuccessContent = () => (
    <>
      {responseData?.upload_time && (
        <>
          <br />
          <strong>Upload Time:</strong>&nbsp;
          {responseData.upload_time}
        </>
      )}
      {responseData?.upload_time && (
        <>
          <br />
          <strong>Created By:</strong>&nbsp;
          {responseData.username}
        </>
      )}
      <br />
      <strong>Message: </strong> Test Plan Excel will be sent over by mail
      shortly. The plan will also be available for download on website in 3-5
      mins
    </>
  );

  return (
    <Modal
      visible={visible}
      header={header}
      onDismiss={handleDismiss}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={handleDismiss}>
              Ok
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {`${message}.`}
      {success && <SuccessContent />}
    </Modal>
  );
};

export default ResponseModal;
