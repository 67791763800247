import React from "react";
import {
  Multiselect,
  FormField,
  Select,
  Input,
  Button,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

const defaultInternalMargin = "3";

export const MultiselectRegion = ({
  options,
  selectedOptions,
  onChange,
  errorText,
}) => (
  <FormField
    label='Select Regions'
    errorText={errorText}
    constraintText='* Required'>
    <Multiselect
      options={options}
      selectedOptions={selectedOptions}
      placeholder='Regions'
      onChange={onChange}
      keepOpen={true}
      tokenLimit={2}></Multiselect>
  </FormField>
);

export const SelectRegion = ({
  options,
  onChange,
  value,
  label,
  errorText,
}) => (
  <FormField label='Region' errorText={errorText}>
    <Select
      options={options}
      placeholder='Region'
      onChange={onChange}
      selectedOption={{
        value: value,
        label: label,
      }}></Select>
  </FormField>
);

export const SelectLab = ({
  options,
  onChange,
  value,
  label,
  disabled,
}) => (
  <FormField
    label='Select Lab'
    constraintText='* Required'>
    <Select
      options={options}
      disabled={disabled}
      placeholder='Labs'
      onChange={onChange}
      selectedOption={{
        value: value,
        label: label,
      }}></Select>
  </FormField>
);

export const AssigneeField = ({ value, onChange  }) => (
  <FormField
    label='Assignee'
    constraintText='* Required, Assignee should be a valid amazon username, emails are not supported'>
    <Input
      id={"assignee"}
      placeholder='assignee'
      value={value}
      onChange={onChange}></Input>
  </FormField>
);

export const SelectTechnology = ({
  options,
  onChange,
  value,
  label,
  errorText,
}) => (
  <FormField label='Technology' errorText={errorText}>
    <Select
      options={options}
      placeholder='Technology'
      onChange={onChange}
      selectedOption={{
        value: value,
        label: label,
      }}></Select>
  </FormField>
);

export const SelectSpecification = ({
  options,
  onChange,
  value,
  label,
  errorText,
}) => (
  <FormField label='Specification' errorText={errorText}>
    <Select
      options={options}
      placeholder='Specification'
      onChange={onChange}
      selectedOption={{
        value: value,
        label: label,
      }}></Select>
  </FormField>
);

export const MultiselectSpecification = ({
  options,
  selectedOptions,
  onChange,
  errorText,
}) => (
  <FormField label='Specifications' errorText={errorText}>
    <Multiselect
      options={options}
      selectedOptions={selectedOptions}
      placeholder='Specifications'
      onChange={onChange}
      keepOpen={true}
      tokenLimit={2}></Multiselect>
  </FormField>
);

export const AntennaField = ({
  options,
  onChange,
  value,
  label,
  errorText,
  showAntennaModel,
}) => (
  <>
    <FormField label='Antenna' errorText={errorText}>
      <Select
        options={options}
        placeholder='Technology'
        onChange={onChange}
        selectedOption={{
          value: value,
          label: label,
        }}></Select>
    </FormField>
    <Button iconName='edit' variant='icon' onClick={showAntennaModel} />
  </>
);

export const InternalMarginField = ({
  errorText,
  value,
  showInternalMarginModel,
}) => (
  <>
    <FormField
      label={"Internal Margin (default is " + defaultInternalMargin + "dB)"}
      errorText={errorText}>
      <Input
        id={"interal margin"}
        placeholder='Enter Internal Margin'
        value={value}
        disabled></Input>
    </FormField>
    <Button onClick={showInternalMarginModel} iconName='edit' variant='icon' />
  </>
);

export const TargetPowerField = ({ value, onChange, errorText }) => {
  return (
    <>
      <FormField label='Target Power' errorText={errorText}>
        <Input
          id={"target"}
          placeholder='Enter Target Power Setting'
          value={value}
          onChange={onChange}></Input>
      </FormField>
    </>
  );
};

export const TargetPowerFieldWithSubPower = ({
  value,
  onChange,
  errorText,
  subValue,
  subOnChange,
}) => {
  return (
    <>
      <FormField label='Target Power' errorText={errorText}>
        <div
          style={{
            display: "flex",
          }}>
          <Input
            id={"target"}
            placeholder='Power Setting'
            value={value}
            onChange={onChange}
          />
          &nbsp;
          <p
            style={{
              fontSize: "20px",
            }}>
            /
          </p>
          &nbsp;
          <Input
            id={"sub target"}
            placeholder='Sub Power Setting'
            value={subValue}
            onChange={subOnChange}
          />
        </div>
      </FormField>
    </>
  );
};

export const MinPowerField = ({ value, onChange, errorText }) => (
  <FormField label='Min Power' errorText={errorText}>
    <Input
      id={"minimum power"}
      placeholder='Enter Min Power Setting allowed'
      value={value}
      onChange={onChange}></Input>
  </FormField>
);

export const MinPowerFieldWithSubPower = ({
  value,
  onChange,
  errorText,
  subValue,
  subOnChange,
}) => (
  <FormField label='Min Power' errorText={errorText}>
    <div
      style={{
        display: "flex",
      }}>
      <Input
        id={"minimum power"}
        placeholder='Power Setting'
        value={value}
        onChange={onChange}
      />
      &nbsp;
      <p
        style={{
          fontSize: "20px",
        }}>
        /
      </p>
      &nbsp;
      <Input
        id={"sub minimum power"}
        placeholder='Sub Power Setting'
        value={subValue}
        onChange={subOnChange}
      />
    </div>
  </FormField>
);

export const AntennaGainField = ({ value, onChange, errorText }) => (
  <FormField label='Antenna Gain (Peak)' errorText={errorText}>
    <Input
      id={"antenna gain"}
      placeholder='Enter Antenna Gain (Peak)'
      value={value}
      onChange={onChange}></Input>
  </FormField>
);
