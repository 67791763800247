import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Input,
  Multiselect,
  Select,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";

import constants from "../../../../constants";
import {
  createTestplan,
  getTestplanConfig,
  resetTestplanOnFailure,
  resetTestplanOnSuccess,
} from "../../../../redux/actions/testplan-action";
import {
  getTestPlanChildrenOptions,
  getTestPlanOptions,
} from "../../../../utils/general-utils";
import {
  COUNTRY_TESTPLAN_MAP,
  TECHNOLOGY_TESTPLAN_MAP,
  antennaDropDown,
} from "../../../../utils/constants";
import TestPlanTable from "./test-plan-table";
import ResponseModal from "./response-modal";

const TestPlanRF = ({
  formMetadata,
  selectedTechnologies,
  selectedCountries,
}) => {
  const dispatch = useDispatch();
  const { techSpec, countries, createStatus, responseData, message } =
    useSelector((state: any) => state.testplanReducer);

  useEffect(() => {
    if (!Object.keys(techSpec || {}).length) dispatch(getTestplanConfig());
  }, [dispatch, techSpec]);

  const [selectedTechnology, setSelectedTechnology] =
    useState<SelectProps.Option | null>(null);
  const [selectedSpecifications, setSelectedSpecifications] = useState<
    SelectProps.Option[]
  >([]);
  const [selectedAntenna, setSelectedAntenna] =
    useState<SelectProps.Option | null>(null);
  const [minPower, setMinPower] = useState<string>("");
  const [internalMargin, setInternalMargin] = useState<string>("");
  const [errors, setErrors] = useState<any>({});
  const [hasDuplicateRecord, setHasDuplicateRecord] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  const selectedCountryIds = useMemo(() => {
    return Object.keys(countries)
      .filter((key) => {
        const country = countries[key].name;
        return Object.keys(COUNTRY_TESTPLAN_MAP).some(
          (selectedCountry) =>
            COUNTRY_TESTPLAN_MAP[selectedCountry] === country &&
            selectedCountries.includes(selectedCountry)
        );
      })
      .map(Number);
  }, [countries, selectedCountries, COUNTRY_TESTPLAN_MAP]);

  const clearError = (fieldName: string) => {
    setErrors((prev) => ({ ...prev, [fieldName]: undefined }));
    setHasDuplicateRecord(false);
  };

  const technologyOptions = useMemo(() => {
    const testPlanTechOptions = getTestPlanOptions(techSpec);
    return testPlanTechOptions.filter((option) =>
      selectedTechnologies.some(
        (technology) => TECHNOLOGY_TESTPLAN_MAP[technology] === option.label
      )
    );
  }, [techSpec, selectedTechnologies, TECHNOLOGY_TESTPLAN_MAP]);

  const handleTechnologySelect = ({ detail }) => {
    clearError("technology");
    setSelectedTechnology(detail.selectedOption);
    setSelectedSpecifications([]);
  };

  const specificationOptions = useMemo(
    () => getTestPlanChildrenOptions(techSpec, selectedTechnology?.value || ""),
    [techSpec, selectedTechnology]
  );
  const handleSpecificationsSelect = ({ detail }) => {
    clearError("specifications");
    setSelectedSpecifications(detail.selectedOptions);
  };

  const handleAntennaSelect = ({ detail }) => {
    clearError("antenna");
    setSelectedAntenna(detail.selectedOption);
  };

  const handleMinPowerChange = ({ detail }) => {
    clearError("minPower");
    setMinPower(detail.value);
  };

  const handleInternalMarginChange = ({ detail }) => {
    clearError("internalMargin");
    setInternalMargin(detail.value);
  };

  const clearSelection = () => {
    setSelectedTechnology(null);
    setSelectedSpecifications([]);
    setSelectedAntenna(null);
    setMinPower("");
    setInternalMargin("");
    setErrors({});
    setHasDuplicateRecord(false);
  };

  const validateForm = () => {
    const newErrors: typeof errors = {};
    const errorMessage = "Required field";

    if (!selectedTechnology) newErrors.technology = errorMessage;
    if (!selectedSpecifications.length) newErrors.specifications = errorMessage;
    if (!selectedAntenna) newErrors.antenna = errorMessage;
    if (!minPower.trim()) newErrors.minPower = errorMessage;
    if (!internalMargin.trim()) newErrors.internalMargin = errorMessage;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddSelection = () => {
    if (!validateForm()) return;

    const data = {
      selectedSpecifications,
      selectedTechnology,
      selectedAntenna,
      minPower,
      internalMargin
    };
    setFormData(data);
  };

  const handleFormDataEntry = ({ isDuplicate }) => {
    setHasDuplicateRecord(isDuplicate);
    !isDuplicate && clearSelection();
  };

  const handleSubmit = (tableItems) => {
    if (!tableItems?.length || !preFormDataExists) return;

    const { categoryId, projectId, buildId, buildConfigId } = formMetadata;
    const requestPayload = {
      countries: selectedCountryIds,
      tech_specs: tableItems.map((item) => ({
        technology_id: +(item.technologyOption?.value || ""),
        tech_spec_id: +(item.specificationOption?.value || ""),
        antenna: item.antennaOption?.value || "",
        minimum_power: item.minPower,
        internal_margin: item.internalMargin,
      })),
    };

    dispatch(
      createTestplan(
        categoryId,
        projectId,
        buildId,
        buildConfigId,
        requestPayload
      )
    );
  };

  const resetForm = (success: boolean) => {
    if (success) {
      clearSelection();
      dispatch(resetTestplanOnSuccess());
    } else {
      dispatch(resetTestplanOnFailure());
    }
  };

  const preFormDataExists =
    selectedTechnologies?.length > 0 &&
    selectedCountries?.length > 0 &&
    Object.keys(formMetadata || {}).length > 0 &&
    Boolean(formMetadata.buildConfigId);
  const loading = preFormDataExists && !technologyOptions?.length;
  const loadingFailed = !preFormDataExists;
  const loadingSuccess = preFormDataExists && technologyOptions?.length > 0;
  const hasAddedRecord = Object.keys(formData || {}).length > 0;

  return (
    <Container header={<Header variant="h2">RF Test Plan</Header>}>
      {loading && <StatusIndicator type="loading">loading</StatusIndicator>}

      {loadingFailed && (
        <StatusIndicator type="error">
          Please confirm the list of technologies and countries identified from
          BRD
        </StatusIndicator>
      )}

      {loadingSuccess && (
        <>
          <ColumnLayout columns={4}>
            <FormField label="Technology" errorText={errors.technology}>
              <Select
                selectedOption={selectedTechnology}
                onChange={handleTechnologySelect}
                options={technologyOptions}
                placeholder="Technology"
              />
            </FormField>

            <FormField label="Specification" errorText={errors.specifications}>
              <Multiselect
                selectedOptions={selectedSpecifications}
                onChange={handleSpecificationsSelect}
                options={specificationOptions}
                placeholder="Specifications"
                disabled={!selectedTechnology}
              />
            </FormField>

            <FormField label="Antenna" errorText={errors.antenna}>
              <Select
                selectedOption={selectedAntenna}
                onChange={handleAntennaSelect}
                options={antennaDropDown}
                placeholder="Antenna"
              />
            </FormField>

            <FormField label="Min Power" errorText={errors.minPower}>
              <Input
                placeholder="Enter Min Power Setting allowed"
                value={minPower}
                onChange={handleMinPowerChange}
              />
            </FormField>
            <FormField label="Internal Margin" errorText={errors.internalMargin}>
              <Input
                  placeholder="Enter Internal Margin"
                  value={internalMargin}
                  onChange={handleInternalMarginChange}
              />
            </FormField>
          </ColumnLayout>

          <br />
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal" onClick={clearSelection}>
              Clear
            </Button>
            <Button variant="primary" onClick={handleAddSelection}>
              Add
            </Button>
          </SpaceBetween>

          {hasDuplicateRecord && (
            <StatusIndicator type="error">
              Duplicate Entry. The combination of Technology, Specification and
              Antenna should always be unique.
            </StatusIndicator>
          )}

          {hasAddedRecord && (
            <>
              <br />
              <TestPlanTable
                formData={formData}
                onFormDataEntry={handleFormDataEntry}
                onSubmit={handleSubmit}
                submitStatus={createStatus}
              />
            </>
          )}

          {createStatus === constants.LOADING_LOAD && (
            <>
              <br />
              <StatusIndicator type="loading">
                Creating Testplan
              </StatusIndicator>
            </>
          )}

          <ResponseModal
            createStatus={createStatus}
            message={message}
            resetForm={resetForm}
            responseData={responseData}
          />
        </>
      )}
    </Container>
  );
};

export default TestPlanRF;
