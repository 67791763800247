import {CONFLUENCE_DOMAIN} from "./constants";

export function validateConfluenceUrl(url: string) {
  try {
    const urlObject = new URL(url);
    if (urlObject.hostname !== CONFLUENCE_DOMAIN) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}