import endpoints from "../../endpoints/endpoints";
import {config} from "../../config/config";
import constants, {METRIC_INFO} from "../../constants";
import aceType from "../types/ace-type";

export const extractSections = ({file_name}) => ({
  type: aceType.EXTRACT_SECTIONS,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.extractSections(),
        method: "POST",
        json: {file_name},
      },
      commit: {
        type: aceType.EXTRACT_SECTIONS_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.EXTRACT_SECTIONS_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const dataSourceParsing = (
  { ace_id, doc_name, section_names },
  onResponse?
) => {
  const requestBody = {
    ace_id,
    doc_name,
    section_names,
  };

  const log = {
    initiative: constants.INITIATIVE_ACE,
    feature: METRIC_INFO.ACE,
  };

  return {
    type: aceType.DATA_SOURCE_PARSING,
    http: {
      cognito: {
        effect: {
          url: config.BASE_URL + endpoints.dataSourceParsing(),
          method: "POST",
          json: requestBody,
        },
        commit: {
          type: aceType.DATA_SOURCE_PARSING_COMMIT,
          log,
          requestBody,
        },
        rollback: {
          type: aceType.DATA_SOURCE_PARSING_ROLLBACK,
          log,
          requestBody,
        },
      },
    },
    onResponse,
  };
};

export const appendTextToAce = (
  { ace_id, text_to_add, text_type},
  onResponse?
) => ({
  type: aceType.APPEND_AND_UPLOAD,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.appendTextToAce(),
        method: "POST",
        json: {ace_id, text_to_add, text_type},
      },
      commit: {
        type: aceType.APPEND_AND_UPLOAD_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.APPEND_AND_UPLOAD_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
  onResponse,
});


export const createAceRecord = (aceDocumentName, plannedCountries, wirelessTechnologies, confluenceParentUrl) => ({
  type: aceType.CREATE_ACE_RECORD,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.createAceRecord(),
        method: "POST",
        json: {
          ace_document_name: aceDocumentName,
          planned_countries: plannedCountries,
          wireless_technologies: wirelessTechnologies,
          confluence_parent_url: confluenceParentUrl,
        },
      },
      commit: {
        type: aceType.CREATE_ACE_RECORD_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.CREATE_ACE_RECORD_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const startJiraAutomation = (aceId, productCode, jiraProjectId, jiraSecurityLevel) => ({
  type: aceType.JIRA_AUTOMATION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.jiraAutomation(),
        method: "POST",
        json: {
          ace_id: aceId,
          product_code_name: productCode,
          jira_project_id: jiraProjectId,
          jira_security_level: jiraSecurityLevel
        },
      },
      commit: {
        type: aceType.JIRA_AUTOMATION_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.JIRA_AUTOMATION_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const addLimitsSectionAction = (aceId, cycleDutyValue, eirpPsd) => ({
  type: aceType.ADD_LIMITS_SECTION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.addLimitsSection(),
        method: "POST",
        json: {
          ace_id: aceId,
          cycle_duty_value: cycleDutyValue,
          eirp_psd: eirpPsd
        },
      },
      commit: {
        type: aceType.ADD_LIMITS_SECTION_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.ADD_LIMITS_SECTION_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const addStandardsSection = ({aceId}) => ({
  type: aceType.ADD_STANDARDS_SECTION,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.addStandardsSection(),
        method: "POST",
        json: {
          ace_id: aceId
        },
      },
      commit: {
        type: aceType.ADD_STANDARDS_SECTION_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.ADD_STANDARDS_SECTION_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

export const downloadAceDocument = (aceFileName) => ({
  type: aceType.DOWNLOAD_ACE_DOCUMENT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.downloadAceDocument(),
        method: "POST",
        json: {
          ace_file_name: aceFileName,
        },
      },
      commit: {
        type: aceType.DOWNLOAD_ACE_DOCUMENT_COMMIT,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
      rollback: {
        type: aceType.DOWNLOAD_ACE_DOCUMENT_ROLLBACK,
        log: {initiative: constants.INITIATIVE_ACE, feature: METRIC_INFO.ACE},
      },
    },
  },
});

