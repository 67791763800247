import aceType from "../types/ace-type";
import constants from "../../constants";

const initialState = {
  extractSectionsRequest: null,
  extractSectionsLoadingStatus: constants.LOADING_DEFAULT,
  extractSectionsResponse: null,

  dataSourceParsingRequest: null,
  dataSourceParsingLoadingStatus: constants.LOADING_DEFAULT,
  dataSourceParsingResponse: null,

  appendAndUploadLoadingStatus: constants.LOADING_DEFAULT,
  appendAndUploadResponse: null,

  jiraAutomationLoadingStatus: constants.LOADING_DEFAULT,
  jiraAutomationResponse: null,

  limitsSectionLoadingStatus: constants.LOADING_DEFAULT,
  limitsSectionResponse: null,

  standardsSectionLoadingStatus: constants.LOADING_DEFAULT,
  standardsSectionResponse: null,

  createAceRecordLoadingStatus: constants.LOADING_DEFAULT,
  createAceRecordResponse: null,

  downloadAceDocumentLoadingStatus: constants.LOADING_DEFAULT,
  downloadAceDocumentResponse: null,
};

const aceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case aceType.RESET_DFC:
      return initialState;
    case aceType.EXTRACT_SECTIONS:
      return {
        ...state,
        extractSectionsRequest: action.http?.cognito?.effect?.json,
        extractSectionsLoadingStatus: constants.LOADING_LOAD,
        extractSectionsResponse: initialState.extractSectionsResponse,
      };
    case aceType.EXTRACT_SECTIONS_COMMIT:
      return {
        ...state,
        extractSectionsLoadingStatus: constants.LOADING_SUCCESS,
        extractSectionsResponse: action.payload,
      };
    case aceType.EXTRACT_SECTIONS_ROLLBACK:
      return {
        ...state,
        extractSectionsLoadingStatus: constants.LOADING_FAIL,
      };
    case aceType.DATA_SOURCE_PARSING:
      return {
        ...state,
        dataSourceParsingRequest: action.http?.cognito?.effect?.json,
        dataSourceParsingLoadingStatus: constants.LOADING_LOAD,
        dataSourceParsingResponse: initialState.dataSourceParsingResponse,
      };
    case aceType.DATA_SOURCE_PARSING_COMMIT:
      return {
        ...state,
        dataSourceParsingRequest: action.requestBody,
        dataSourceParsingLoadingStatus: constants.LOADING_SUCCESS,
        dataSourceParsingResponse: action.payload,
      };
    case aceType.DATA_SOURCE_PARSING_ROLLBACK:
      return {
        ...state,
        dataSourceParsingRequest: action.requestBody,
        dataSourceParsingLoadingStatus: constants.LOADING_FAIL,
        dataSourceParsingResponse: action.payload,
      };
    case aceType.APPEND_AND_UPLOAD:
      return {
        ...state,
        appendAndUploadLoadingStatus: constants.LOADING_LOAD,
        appendAndUploadResponse: initialState.appendAndUploadResponse,
      };
    case aceType.APPEND_AND_UPLOAD_COMMIT:
      return {
        ...state,
        appendAndUploadLoadingStatus: constants.LOADING_SUCCESS,
        appendAndUploadResponse: action.payload,
      };
    case aceType.APPEND_AND_UPLOAD_ROLLBACK:
      return {
        ...state,
        appendAndUploadLoadingStatus: constants.LOADING_FAIL,
        appendAndUploadResponse: action.payload,
      };
    case aceType.CREATE_ACE_RECORD:
      return {
        ...state,
        createAceRecordLoadingStatus: constants.LOADING_LOAD,
        createAceRecordResponse: initialState.createAceRecordResponse,
      };
    case aceType.CREATE_ACE_RECORD_COMMIT:
      return {
        ...state,
        createAceRecordLoadingStatus: constants.LOADING_SUCCESS,
        createAceRecordResponse: action.payload,
      };
    case aceType.CREATE_ACE_RECORD_ROLLBACK:
      return {
        ...state,
        createAceRecordLoadingStatus: constants.LOADING_FAIL,
      };
    case aceType.JIRA_AUTOMATION:
      return {
        ...state,
        jiraAutomationLoadingStatus: constants.LOADING_LOAD,
        jiraAutomationResponse: initialState.jiraAutomationResponse,
      };
    case aceType.JIRA_AUTOMATION_COMMIT:
      return {
        ...state,
        jiraAutomationLoadingStatus: constants.LOADING_SUCCESS,
        jiraAutomationResponse: action.payload,
      };
    case aceType.JIRA_AUTOMATION_ROLLBACK:
      return {
        ...state,
        jiraAutomationLoadingStatus: constants.LOADING_FAIL,
      };
    case aceType.ADD_LIMITS_SECTION:
      return {
        ...state,
        limitsSectionLoadingStatus: constants.LOADING_LOAD,
        limitsSectionResponse: initialState.limitsSectionResponse,
      };
    case aceType.ADD_LIMITS_SECTION_COMMIT:
      return {
        ...state,
        limitsSectionLoadingStatus: constants.LOADING_SUCCESS,
        limitsSectionResponse: action.payload,
      };
    case aceType.ADD_LIMITS_SECTION_ROLLBACK:
      return {
        ...state,
        limitsSectionLoadingStatus: constants.LOADING_FAIL,
      };
    case aceType.ADD_STANDARDS_SECTION:
      return {
        ...state,
        standardsSectionLoadingStatus: constants.LOADING_LOAD,
        standardsSectionResponse: initialState.standardsSectionResponse,
      };
    case aceType.ADD_STANDARDS_SECTION_COMMIT:
      return {
        ...state,
        standardsSectionLoadingStatus: constants.LOADING_SUCCESS,
        standardsSectionResponse: action.payload,
      };
    case aceType.ADD_STANDARDS_SECTION_ROLLBACK:
      return {
        ...state,
        standardsSectionLoadingStatus: constants.LOADING_FAIL,
      };
    case aceType.DOWNLOAD_ACE_DOCUMENT:
      return {
        ...state,
        downloadAceDocumentLoadingStatus: constants.LOADING_LOAD,
        downloadAceDocumentResponse: initialState.downloadAceDocumentResponse,
      };
    case aceType.DOWNLOAD_ACE_DOCUMENT_COMMIT:
      return {
        ...state,
        downloadAceDocumentLoadingStatus: constants.LOADING_SUCCESS,
        downloadAceDocumentResponse: action.payload,
      };
    case aceType.DOWNLOAD_ACE_DOCUMENT_ROLLBACK:
      return {
        ...state,
        downloadAceDocumentLoadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default aceReducer;
