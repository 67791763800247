import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

import constants from "../../../constants";
import { addStandardsSection } from "../../../redux/actions/ace-action";
import { getAceFileName } from "./ace-utils";

const AceRadiatedTestingStandards = ({ aceId }) => {
  const dispatch = useDispatch();
  const { standardsSectionLoadingStatus } = useSelector(
    (state: any) => state.aceReducer
  );

  const handleAddStandardsSection = () => {
    const requestBody = {
      aceId: aceId,
    };

    dispatch(addStandardsSection(requestBody));
  };

  const disabled = [constants.LOADING_LOAD, constants.LOADING_SUCCESS].includes(
    standardsSectionLoadingStatus
  );

  return (
    <>
      <Header variant="h2">Radiated testing standards</Header>
      <SpaceBetween size="s" direction="vertical">
        <Button
          variant="primary"
          onClick={handleAddStandardsSection}
          disabled={disabled}
        >
          Add standards section
        </Button>
        {standardsSectionLoadingStatus == constants.LOADING_LOAD && (
          <Box color={"text-status-info"}>
            Please wait while we add the radiated testing standards section ...
          </Box>
        )}
        {standardsSectionLoadingStatus == constants.LOADING_SUCCESS && (
          <Box color={"text-status-success"}>
            Radiated testing standards section added successfully
          </Box>
        )}
        {standardsSectionLoadingStatus == constants.LOADING_FAIL && (
          <Box color={"text-status-error"}>
            Could not add radiated testing standards section. Please try again.
          </Box>
        )}
      </SpaceBetween>
    </>
  );
};

export default AceRadiatedTestingStandards;
