import React from "react";
import {
  Input,
  Header,
  FormField,
  SpaceBetween,
  Select,
  Button,
  Box,
  SelectProps
} from "@amzn/awsui-components-react-v3";
import {getJiraSecurityLevels} from "./ace-utils";
import {useDispatch, useSelector} from "react-redux";
import {startJiraAutomation} from "../../../redux/actions/ace-action";
import constants from "../../../constants";

type ValidationState = {
  isValid: boolean;
  productCodeMessage?: string;
  jiraProjectIdMessage?: string;
  jiraSecurityLevelMessage?: string;
}


function AceJiraAutomation(props) {
  const dispatch = useDispatch();
  const aceReducer = useSelector((state: any) => state.aceReducer);

  const aceId = props.aceId;
  const [productCode, setProductCode] = React.useState("");
  const [jiraProjectId, setJiraProjectId] = React.useState("");
  const [jiraSecurityLevel, setJiraSecurityLevel] = React.useState<SelectProps.Option | null>(null);

  const [validationState, setValidationState] = React.useState<ValidationState>({
    isValid: true,
  });

  const validateInputs = () => {
    let isValid = true;
    const validationState: ValidationState = {
      isValid: true
    };
    if (!productCode || productCode.includes(" ")) {
      validationState["productCodeMessage"] = "Product code is invalid. It cannot be empty and cannot contain" +
        " spaces"
      isValid = false;
    }
    if (!jiraProjectId || jiraProjectId.includes(" ")) {
      validationState["jiraProjectIdMessage"] = "Jira project id is invalid. It cannot be empty and" +
        " cannot contain spaces"
      isValid = false;
    }
    if (!jiraSecurityLevel) {
      validationState["jiraSecurityLevelMessage"] = "Jira security level cannot be empty"
      isValid = false;
    }
    validationState["isValid"] = isValid;
    setValidationState(validationState)
    return isValid;
  }

  const onCreateJirasButtonClick = () => {
    const isValid = validateInputs();
    if (isValid) {
      dispatch(startJiraAutomation(aceId, productCode, jiraProjectId, jiraSecurityLevel?.["value"]));
    }
  }

  const clearValidationState = () => {
    setValidationState({
      isValid: true,
      productCodeMessage: "",
      jiraProjectIdMessage: "",
      jiraSecurityLevelMessage: ""
    })
  }

  return (
    <div>
      <Header
        variant="h2"
      >
        Create channel plan Jira
      </Header>
      <SpaceBetween
        size="s"
        direction="vertical"
      >
        <FormField
          label="Product Code"
          description="This will be used in title of created Jiras"
          errorText={validationState?.productCodeMessage}
        >
          <Input
            value={productCode}
            placeholder="Enter product code"
            onChange={event => {
              clearValidationState();
              setProductCode(event.detail.value)
            }}
          />
        </FormField>
        <FormField
          label="Jira Project Id"
          errorText={validationState?.jiraProjectIdMessage}
        >
          <Input
            value={jiraProjectId}
            placeholder="Enter Jira project id"
            onChange={event => {
              clearValidationState();
              setJiraProjectId(event.detail.value)
            }}
          />
        </FormField>
        <FormField
          label="Jira Security Level"
          errorText={validationState?.jiraSecurityLevelMessage}
        >
          <Select
            selectedOption={jiraSecurityLevel}
            onChange={({detail}) => {
              clearValidationState();
              setJiraSecurityLevel(detail.selectedOption)
            }}
            options={getJiraSecurityLevels()}
            placeholder="Select Jira security level"
          />
        </FormField>
        <Button
          variant="primary"
          onClick={() => onCreateJirasButtonClick()}
          disabled={
            aceReducer.jiraAutomationLoadingStatus == constants.LOADING_LOAD ||
            aceReducer.jiraAutomationLoadingStatus == constants.LOADING_SUCCESS
          }
        >
          Create Jiras
        </Button>
        {
          aceReducer.jiraAutomationLoadingStatus == constants.LOADING_LOAD &&
            <Box
              color={"text-status-info"}
            >
                Please wait while we create the Jiras ...
            </Box>
        }
        {
          aceReducer.jiraAutomationLoadingStatus == constants.LOADING_SUCCESS &&
            <Box
                color={"text-status-success"}
            >
                Jira created successfully
            </Box>
        }
        {
          aceReducer.jiraAutomationLoadingStatus == constants.LOADING_FAIL &&
            <Box
                color={"text-status-error"}
            >
                Jira creation failed. Please try again.
            </Box>
        }
      </SpaceBetween>
    </div>
  );
}

export default AceJiraAutomation;
