import {CYCLE_DUTY_VALUES, EIRP_PSD_VALUES, JIRA_SECURITY_LEVELS, Status} from "../../../constants";

export type SectionProgressT = {
  [header: string]: { [fileName: string]: { [section: string]: Status } };
};

export function getJiraSecurityLevels(){
    return JIRA_SECURITY_LEVELS.map(level => {
        return {
            label: level,
            value: level
        }
    });
}

export function getCycleDutyValues(){
    return CYCLE_DUTY_VALUES.map(level => {
        return {
            label: level,
            value: level
        }
    });
}

export function getEirpPsdValues(){
    return EIRP_PSD_VALUES.map(level => {
        return {
            label: level,
            value: level
        }
    });
}

export function getAceFileName(aceId){
    return `${aceId}.docx`;
}

export const getSectionStatusMap = (sectionProgress: SectionProgressT) => {
  const statusMap: Record<Status, string[]> = {
    [Status.Success]: [],
    [Status.InProgress]: [],
    [Status.Pending]: [],
    [Status.Failed]: [],
  };

  Object.values(sectionProgress).forEach((fileSections) => {
    Object.values(fileSections).forEach((sectionMap) => {
      Object.entries(sectionMap).forEach(([section, sectionStatus]) => {
        statusMap[sectionStatus].push(section);
      });
    });
  });
  return statusMap;
};

export function getConfluencePageDetails(confluenceUrl: string){
  try {
    const urlObject = new URL(confluenceUrl);
    const pathParts = urlObject.pathname.split('/');
    return {
      space: pathParts[3],
      pageName:decodeURIComponent(pathParts[4]).replace(/\+/g, ' ')
    };
  } catch (error) {
    return {
      space: '',
      pageName: ''
    };
  }
}