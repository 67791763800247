import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3";

import constants from "../../../../constants";
import { getTestplanConfig } from "../../../../redux/actions/testplan-action";
import {
  getBuildConfigItems,
  getBuildId,
  getBuildItems,
  getProjectItems,
  getTestPlanOptions,
  getVersionItems,
} from "../../../../utils/general-utils";
import TestPlanRF from "./test-plan-rf";
import TestPlanEMI from "./test-plan-emi";

const TestPlan = ({ selectedTechnologies, selectedCountries }) => {
  const dispatch = useDispatch();
  const {
    metadataReducer: { loadingStatus, filter },
    testplanReducer: { techSpec },
  } = useSelector((state: any) => state);

  const preFormMetadataExists = Object.keys(filter || {}).length > 0;

  useEffect(() => {
    if (!Object.keys(techSpec || {}).length) dispatch(getTestplanConfig());
  }, [dispatch, techSpec]);

  const [selectedCategory, setSelectedCategory] =
    useState<SelectProps.Option | null>(null);
  const [selectedProject, setSelectedProject] =
    useState<SelectProps.Option | null>(null);
  const [selectedBuild, setSelectedBuild] = useState<SelectProps.Option | null>(
    null
  );
  const [selectedVersion, setSelectedVersion] =
    useState<SelectProps.Option | null>(null);
  const [selectedBuildConfig, setSelectedBuildConfig] =
    useState<SelectProps.Option | null>(null);

  const [errors, setErrors] = useState<any>({});
  const [formMetadata, setFormMetadata] = useState<any>(null);

  const categoryOptions = useMemo(() => getTestPlanOptions(filter), [filter]);
  const handleCategorySelect = ({ detail }) => {
    setErrors((prev) => ({ ...prev, category: undefined }));
    setSelectedCategory(detail.selectedOption);
    setSelectedProject(null);
    setSelectedBuild(null);
    setSelectedVersion(null);
    setSelectedBuildConfig(null);
    setFormMetadata(null);
  };

  const projectOptions = useMemo(
    () => getProjectItems(filter, selectedCategory?.value || ""),
    [filter, selectedCategory]
  );
  const handleProjectSelect = ({ detail }) => {
    setErrors((prev) => ({ ...prev, project: undefined }));
    setSelectedProject(detail.selectedOption);
    setSelectedBuild(null);
    setSelectedVersion(null);
    setSelectedBuildConfig(null);
    setFormMetadata(null);
  };

  const buildOptions = useMemo(
    () =>
      getBuildItems(
        filter,
        selectedCategory?.value || "",
        selectedProject?.value || ""
      ),
    [filter, selectedCategory, selectedProject]
  );
  const handleBuildSelect = ({ detail }) => {
    setErrors((prev) => ({ ...prev, build: undefined }));
    setSelectedBuild(detail.selectedOption);
    setSelectedVersion(null);
    setSelectedBuildConfig(null);
    setFormMetadata(null);
  };

  const versionOptions = useMemo(
    () =>
      getVersionItems(
        filter,
        selectedCategory?.value || "",
        selectedProject?.value || "",
        selectedBuild?.value || ""
      ),
    [filter, selectedCategory, selectedProject, selectedBuild]
  );
  const handleVersionSelect = ({ detail }) => {
    setErrors((prev) => ({ ...prev, version: undefined }));
    setSelectedVersion(detail.selectedOption);
    setSelectedBuildConfig(null);
    setFormMetadata(null);
  };

  const buildParams = useMemo<[any, string, string, string, string]>(
    () => [
      filter,
      selectedCategory?.value || "",
      selectedProject?.value || "",
      selectedBuild?.value || "",
      selectedVersion?.value || "",
    ],
    [filter, selectedCategory, selectedProject, selectedBuild, selectedVersion]
  );
  const buildId = useMemo(() => getBuildId(...buildParams), [buildParams]);
  const buildConfigOptions = useMemo(
    () => getBuildConfigItems(...buildParams),
    [buildParams]
  );
  const handleBuildConfigSelect = ({ detail }) => {
    setErrors((prev) => ({ ...prev, buildConfig: undefined }));
    setSelectedBuildConfig(detail.selectedOption);
    setFormMetadata(null);
  };

  const clearSelection = () => {
    setSelectedCategory(null);
    setSelectedProject(null);
    setSelectedBuild(null);
    setSelectedVersion(null);
    setSelectedBuildConfig(null);
    setFormMetadata(null);
    setErrors({});
  };

  const validateForm = () => {
    const newErrors: typeof errors = {};
    const errorMessage = "Required field";

    if (!selectedCategory) newErrors.category = errorMessage;
    if (!selectedProject) newErrors.project = errorMessage;
    if (!selectedBuild) newErrors.build = errorMessage;
    if (!selectedVersion) newErrors.version = errorMessage;
    if (!selectedBuildConfig) newErrors.buildConfig = errorMessage;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const data = {
      categoryId: selectedCategory?.value,
      projectId: selectedProject?.value,
      buildId,
      buildConfigId: selectedBuildConfig?.value,
    };
    setFormMetadata(data);
  };

  const loading = loadingStatus === constants.LOADING_LOAD;
  const loadingFailed = loadingStatus === constants.LOADING_FAIL;
  const loadingSuccess =
    loadingStatus === constants.LOADING_SUCCESS && preFormMetadataExists;
  const hasSubmittedData =
    Object.keys(formMetadata || {}).length > 0 && Boolean(formMetadata.buildId);

  return (
    <Container header={<Header variant="h2">Create Testplan</Header>}>
      {loading && <StatusIndicator type="loading">loading</StatusIndicator>}

      {loadingFailed && (
        <StatusIndicator type="error">Failed to load metadata</StatusIndicator>
      )}

      {loadingSuccess && (
        <>
          <ColumnLayout columns={3}>
            <FormField label="Category" errorText={errors.category}>
              <Select
                selectedOption={selectedCategory}
                onChange={handleCategorySelect}
                options={categoryOptions}
                placeholder="Category"
              />
            </FormField>

            <FormField label="Project" errorText={errors.project}>
              <Select
                selectedOption={selectedProject}
                onChange={handleProjectSelect}
                options={projectOptions}
                placeholder="Project"
                disabled={!selectedCategory}
              />
            </FormField>

            <FormField label="Build" errorText={errors.build}>
              <Select
                selectedOption={selectedBuild}
                onChange={handleBuildSelect}
                options={buildOptions}
                placeholder="Build"
                disabled={!selectedProject}
              />
            </FormField>

            <FormField label="Version" errorText={errors.version}>
              <Select
                selectedOption={selectedVersion}
                onChange={handleVersionSelect}
                options={versionOptions}
                placeholder="Version"
                disabled={!selectedBuild}
              />
            </FormField>

            <FormField label="Build Config" errorText={errors.buildConfig}>
              <Select
                selectedOption={selectedBuildConfig}
                onChange={handleBuildConfigSelect}
                options={buildConfigOptions}
                placeholder="Build Config"
                disabled={!selectedVersion}
              />
            </FormField>
          </ColumnLayout>

          <br />
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal" onClick={clearSelection}>
              Clear
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={hasSubmittedData}
            >
              Create Plan
            </Button>
          </SpaceBetween>

          {hasSubmittedData && (
            <>
              <br />
              <TestPlanRF
                formMetadata={formMetadata}
                selectedTechnologies={selectedTechnologies}
                selectedCountries={selectedCountries}
              />
              <br />
              <TestPlanEMI formMetadata={formMetadata} />
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default TestPlan;
