import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Button,
  Container,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

import { setTestOverviewDetailsEMI } from "../../../../redux/actions/testplan-action-emi";

const TestPlanEMI = ({ formMetadata }) => {
  const preFormDataExists =
    Object.keys(formMetadata || {}).length > 0 && Boolean(formMetadata.buildId);

  if (!preFormDataExists) return null;

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const requestPayload = {
      categoryId: formMetadata.categoryId,
      projectId: formMetadata.projectId,
      buildVersionId: formMetadata.buildId,
    };
    dispatch(setTestOverviewDetailsEMI(requestPayload));
  }, []);

  const handleNavigation = () => {
    history.push(`/testplan/emi/create`);
  };

  return (
    <Container header={<Header variant="h2">EMI Test Plan</Header>}>
      <SpaceBetween direction="horizontal" size="xs">
        <Button variant="primary" onClick={handleNavigation}>
          Go To Page
        </Button>
      </SpaceBetween>
    </Container>
  );
};

export default TestPlanEMI;
